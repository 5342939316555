import React, { useState, useEffect } from "react";
import { IoCloseSharp } from "react-icons/io5";
import { RiMenu5Fill } from "react-icons/ri";
import { HashLink } from "react-router-hash-link";

const NavEvent = () => {
  const [open, setOpen] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [dropdownContact, setDropdownContact] = useState(false);
  const [dropdownTakeOut, setDropdownTakeOut] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);

  const handleScroll = () => {
    const currentScrollPos = window.scrollY;
    if (currentScrollPos === 0) {
      setVisible(true);
    } else if (currentScrollPos > prevScrollPos && !open) {
      setVisible(false);
    } else {
      setVisible(true);
    }
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  return (
    // <nav className={`${visible ? 'visible' : 'hidden'} ${window.scrollY === 0 && !open ? "bg-transparent": "bg-th-white lg:bg-th-white shadow-md"}  fixed z-50 top-0 w-screen left-1/2 -translate-x-1/2 xl:px-2 px-0 pt-3 pb-2 md:pt-1 xl:pt-2 md:pb-1 overflow-hidden transition-all duration-500`}>
    <nav
      className={`visible bg-th-white lg:bg-th-white shadow-md fixed z-50 top-0 w-screen left-1/2 -translate-x-1/2 transition-all duration-500`}
    >
      <section className="xl:px-2 px-0 pt-3 pb-2 md:pt-1 xl:pt-2 md:pb-1">
        <div
          className={`text-black w-screen px-2 lg:px-5 2xl:px-14 flex justify-between xl:justify-center items-center flex-wrap xl:flex-nowrap gap-0 md:gap-1 xl:gap-0 mx-auto font-primary lowercase text-base tracking-wider`}
        >
          <a href="/" className="block mr-0 lg:mr-10 py-0 lg:py-2">
            <img
              src={`/assets/images/logos/farmhouse_logo_red.png`}
              className="w-[100px] md:w-[140px] md:mx-auto h-auto max-h-[40px] lg:max-h-[90px] object-contain"
              alt="Farmhouse Logo"
            />
          </a>
          <div className="block xl:hidden pr-2">
            {open ? (
              <div
                className={`text-black cursor-pointer`}
                onClick={() => setOpen((prev) => !prev)}
              >
                <IoCloseSharp size={25} />
              </div>
            ) : (
              <div
                className={`text-black cursor-pointer`}
                onClick={() => setOpen((prev) => !prev)}
              >
                <RiMenu5Fill size={25} />
              </div>
            )}
          </div>
          <div
            className={`${
              open
                ? "translate-x-0 min-h-screen h-screen overflow-y-scroll"
                : "translate-x-[1000px] xl:translate-x-0 min-h-0"
            } bg-th-white lg:bg-transparent w-screen h-0 xl:h-auto xl:w-7/12 transition-all duration-500`}
          >
            <ul
              className={`text-black text-center h-screen xl:h-auto justify-start md:justify-center space-y-3 xl:space-y-0 xl:justify-around flex flex-col items-center p-0 lg:p-4 mt-12 xl:flex-row space-x-0 lg:space-x-2 md:mt-0`}
            >
              <li>
                <HashLink
                  smooth
                  to="/#about"
                  onClick={() => setOpen(false)}
                  className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                >
                  About
                </HashLink>
              </li>
              <li>
                <button
                  onClick={() => setDropdown((prev) => !prev)}
                  onMouseEnter={() => setDropdown(true)}
                  className="w-full lg:w-auto  tracking-wider flex justify-center items-center py-2 pr-4 pl-3 whitespace-nowrap lowercase"
                  type="button"
                >
                  menus{" "}
                  <svg
                    className="w-4 h-4 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    ></path>
                  </svg>
                </button>
                <div
                  className={`${
                    dropdown ? "block" : "hidden"
                  } static lg:fixed lg:top-16 z-50 bg-white divide-y-0 lg:divide-y divide-gray-100 rounded-none lg:rounded-lg shadow-none lg:shadow w-auto lg:w-44`}
                >
                  <ul
                    onMouseLeave={() => setDropdown(false)}
                    className="py-2 text-sm text-black"
                  >
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_Brunch.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        brunch
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_Lunch_Menu.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        lunch
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_Main_Menu.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        dinner
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_Mangia_Wednesdays.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Wednesday's feature
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="assets/menus/the_farmhouse_holiday_features.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Holiday features
                      </a>
                    </li> */}
                    {/* <li>
                      <a
                        href="assets/menus/the_farmhouse_new_year.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        New Year's Eve
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_prix_fixe.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        Classic Weekday Menu
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_farmhouse _Dessert.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        dessert
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_Happy_Hour.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        happy hour
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="assets/menus/the_farmhouse_valentine_day.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        valentine's day
                      </a>
                    </li> */}
                    <li>
                      <a
                        href="assets/menus/the_Farmhouse_Wine_List.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        wine list
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_farmhouse_Beverages.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        beverages
                      </a>
                    </li>
                    <li>
                      <a
                        href="assets/menus/the_Farmhouse_easter.pdf"
                        target="_blank"
                        className="block px-4 py-2 hover:bg-gray-200"
                      >
                        buona pasqua!
                      </a>
                    </li>
                  </ul>
                </div>
              </li>

              <li>
                <a
                  href="/take-out"
                  onClick={() => setOpen(false)}
                  className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                >
                  <button
                    onClick={() => setDropdownTakeOut((prev) => !prev)}
                    onMouseEnter={() => setDropdownTakeOut(true)}
                    className="w-full lg:w-auto tracking-wider flex justify-center items-center py-2 pr-4 pl-3 whitespace-nowrap lowercase"
                    type="button"
                  >
                    take-out{" "}
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                </a>
                <div
                  className={`${
                    dropdownTakeOut ? "block" : "hidden"
                  } static lg:fixed lg:top-16 z-50 bg-white divide-y-0 lg:divide-y divide-gray-100 rounded-none lg:rounded-lg shadow-none lg:shadow w-auto lg:w-44`}
                >
                  <ul
                    onMouseLeave={() => setDropdownTakeOut(false)}
                    className="py-2 text-sm text-black"
                  >
                    <li>
                      <a
                        href="https://www.ubereats.com/ca/store/the-farmhouse/jP1sRy4iWFGJ5flpKRxGzQ"
                        target="_blank"
                        onClick={() => setOpen(false)}
                        rel="noreferrer"
                        className="hover:bg-gray-200 block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                      >
                        uber eats
                      </a>
                    </li>
                    {/* <li>
                      <a
                        href="https://www.skipthedishes.com/the-farmhouse-east-10th-avenue"
                        target="_blank"
                        onClick={() => setOpen(false)}
                        rel="noreferrer"
                        className="hover:bg-gray-200 block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                      >
                        skip the dishes
                      </a>
                    </li> */}
                  </ul>
                </div>
              </li>
              <li>
                <a
                  href="/groupbookings"
                  onClick={() => setOpen(false)}
                  className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                >
                  group dining
                </a>
              </li>
              <li>
                <a
                  href="https://www.tbdine.com/book/restaurant/the-farmhouse-restaurant?idApp=70973&language=en-us"
                  target="_blank"
                  rel="noreferrer"
                  className="block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                >
                  RESERVE NOW
                </a>
              </li>
              <li>
                <a
                  href="/contact"
                  onClick={() => setOpen(false)}
                  className="block px-4 py-2"
                >
                  <button
                    onClick={() => setDropdownContact((prev) => !prev)}
                    onMouseEnter={() => setDropdownContact(true)}
                    className="w-full tracking-wider lg:w-auto flex justify-center items-center py-2 pr-4 pl-3 whitespace-nowrap lowercase"
                    type="button"
                  >
                    contact{" "}
                    <svg
                      className="w-4 h-4 ml-2"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      ></path>
                    </svg>
                  </button>
                </a>
                <div
                  className={`${
                    dropdownContact ? "block" : "hidden"
                  }  tracking-wider static lg:fixed lg:top-16 z-50 bg-white divide-y-0 lg:divide-y divide-gray-100 rounded-none lg:rounded-lg shadow-none lg:shadow w-auto lg:w-44`}
                >
                  <ul
                    onMouseLeave={() => setDropdownContact(false)}
                    className="py-2 text-sm text-black"
                  >
                    <li>
                      <a
                        href="https://www.viaggiohospitality.com/giftcards"
                        target="_blank"
                        rel="noreferrer"
                        className="hover:bg-gray-200 block py-2 pr-4 pl-3 border-transparent hover:border-th-yellow border-t-none border-t-0 lg:border-t-2 whitespace-nowrap lowercase"
                      >
                        Gift Cards
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </nav>
  );
};

export default NavEvent;
